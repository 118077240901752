<template>
  <div>
    <!-- SECCIONES -->
    <vx-card v-if="isMounted && !failed">
      <div slot="no-body" class="tabs-container px-6 pt-6">
        <vs-tabs v-model="activeTab" class="tab-action-btn-fill-container">
          <vs-tab :label="titleForTab(group)" tag-color="000000" icon-pack="feather" :icon="group.icon" v-for="(group, i) in sectionGroups" :key="i">
            <div class="tab-text">
              <vs-alert v-if="group.failed > 0" icon-pack="feather" style="height:auto" icon="icon-alert-triangle" class="mt-1 mb-3" color="danger">
                <span class="font-regular">Verifique los siguientes campos faltantes:<br>
                  <span v-for="(miss, i) in group.missing" :key="i">
                    <strong>- {{miss.n}}: </strong> {{miss.e}}<br>
                  </span>
                </span>
              </vs-alert>

              <vx-card v-for="section in group.sections" :key="section.data.id" :title="section.data.public_name" class="mb-5">
                <p class="mb-4" v-if="section.data.public_description != null">{{section.data.public_description}}</p>

                <div class="vx-row mt-2 mb-6" v-if="group.slug === 'bank' && isBankAccountState">
                  <div class="vx-col w-full">
                    <vs-alert v-if="isBankAccountInRevision" icon-pack="feather" icon="icon-clock" color="warning">
                      <span class="font-regular">La verificación de la cuenta de banco está <strong>en proceso</strong>.</span>
                    </vs-alert>

                    <vs-alert v-if="isBankAccountVerified" icon-pack="feather" icon="icon-check" color="success">
                      <span class="font-regular">La cuenta de banco ha sido <strong>verificada con éxito</strong>.</span>
                    </vs-alert>

                    <vs-button type="border" v-if="isBankAccountVerified" class="mt-4" @click="onUnlockBankAccountRequest" >Actualizar cuenta bancaria</vs-button>
                  </div>
                </div>

                <div class="vx-row">
                  <template v-for="f in section.data.fields">

                    <!-- Campos automaticos -->
                    <form-field
                      :ref="group.slug"
                      v-if="isAutoFormField(f)"
                      :key="f.id"
                      :f="f"
                      :dataContainer="getContainerForField(section, f)"
                      :collectionsRoot="collections"
                      :onChangeReceptor="onFieldChange"
                      :hasBackofficeAccess="hasBackofficeAccess"
                      :blockedByDocument="f.blockedByDocument"
                      :evaluator="evaluateCondition"
                      :countriesList="collections.countries"
                      :editableByBackoffice="true" />

                    <!-- Campos especiales -->

                    <personal-profile-tins-control
                      :key="f.id"
                      :f="f"
                      :personal_profile_id="personal.id"
                      ref="personal_profile_tins_component"
                      v-else-if="f.fname == 'personal_tins' && should_fill_personal_tins"
                      :disabled="f.blockedByDocument"
                      :countries="collections.countries" />
                    <!--:disabled="f.blockedByDocument || isBackoffice"-->

                    <business-profile-tins-control
                      :key="f.id"
                      :f="f"
                      :business_profile_id="business.id"
                      ref="business_profile_tins_component"
                      v-else-if="f.fname == 'business_tins' && should_fill_person_type_3_or_4"
                      :disabled="f.blockedByDocument"
                      :countries="collections.countries" />
                    <!--:disabled="f.blockedByDocument || isBackoffice"-->

                  </template>
                </div>

                <template v-if="section.data.documentFields.length > 0 && !refreshingDocs">
                  <vs-divider></vs-divider>
                  <h6 class="mb-3">Documentos</h6>
                  <div class="vx-row">
                    <document-upload
                      v-for="df in section.data.documentFields"
                      :key="df.id"
                      :target_id="df.target_id"
                      :f="df.formField"
                      :doc="df.documentSet"
                      :evaluator="evaluateCondition"
                      :loadedState="df.loadedState"
                      :stickToLoadedState="true"
                      @on-success="onDocumentUploadSuccess"
                      @on-rejected="onDocumentRejected"
                      @on-verified="onDocumentVerified"
                      @on-revoked-verification="onDocumentVerificationRevoked"
                      :editableByBackoffice="true"
                    />
                  </div>
                </template>
              </vx-card>

              <!-- Save Button -->
              <!--div class="vx-row" v-if="!isBackoffice"-->
              <div class="vx-row mt-10">
                <div class="vx-col w-full">
                  <div class="flex flex-wrap items-center justify-end">
                    <vs-button :id="saveBtnId" class="ml-auto mt-2 mb-8" @click="saveTabChanges(group.slug)">Guardar cambios</vs-button>
                  </div>
                </div>
              </div>

            </div>
          </vs-tab>

        </vs-tabs>

      </div>
    </vx-card>

    <load-error v-if="failed" />

  </div>
</template>

<script>
import inputHelper from '@components/mixins/inputHelper';
import dateHelper from '@components/mixins/dateHelper';
import formHelper from '@components/mixins/formHelper';
import docsHelper from '@components/mixins/docsHelper';
import FormField from '@components/forms/FormField.vue'
import PersonalProfileTinsControl from '@supplier/PersonalProfileTinsControl.vue'
import BusinessProfileTinsControl from '@supplier/BusinessProfileTinsControl.vue'
import {Validator} from 'vee-validate'

const pfaeTabsDefs = [
  { slug: "general", title: "Sobre ti",  icon: "icon-briefcase", sections:[100,101,63] },
  { slug: "address", title: "Domicilio", icon: "icon-map-pin", sections:[102] },
  //{ slug: "bank", title: "Banco", icon: "icon-credit-card", sections:[103] }
];

const pmTabsDefs = [
  { slug: "general", title: "Datos Generales", icon: "icon-briefcase", sections:[104,90] },
  { slug: "address", title: "Domicilio", icon: "icon-map-pin", sections:[105] },
  { slug: "contact", title: "Representante legal", icon: "icon-user-check", sections:[106] },
  //{ slug: "bank", title: "Banco", icon: "icon-credit-card", sections:[107] },
];

const sectionsToContainers = [
  { id: 63, containers: [{db:'personal_profiles', path:'user.personal'}] },
  { id: 90, containers: [{db:'business_profiles', path:'user.business'}] },
  { id: 100, containers: [{db:'users', path:'user'}, {db:'personal_profiles', path:'user.personal'}] },
  { id: 101, containers: [{db:'personal_profiles', path:'user.personal'}, {db:'personal_profiles_tins', path:'user.personal.tins', arrayDefs: {}}] },
  { id: 102, containers: [
    {db:'personal_profiles', path:'user.personal'}
    , {db:'addresses', path:'user.personal.address'}
    , {db:'phones', path:'user.personal.phone', arrayDefs:{ onNull: 'newPhone'}}
  ]},
  /*{ id: 103, containers: [
      {db:'bank_accounts', path:'user.personal.bank_accounts', arrayDefs: {getFirst: true, onNull: 'newBankAccount'}},
      {db:'banks', path: 'bank_accounts_bank' },
    ]},*/
  { id: 104, containers: [
      {db:'business_profiles', path:'user.business'},
      {db:'business_profile_tins', path:'user.business.tins', arrayDefs:{}}
    ]},
  { id: 105, containers: [
      {db:'business_profiles', path:'user.business'},
      {db:'addresses', path:'user.business.address'},
      {db:'phones', path:'user.business.phone', arrayDefs:{ onNull: 'newPhone'}},
    ]},
  { id: 106, containers: [{db:'personal_profiles', path:'user.business.personal'}]},
  /*{ id: 107, containers: [
    {db:'bank_accounts', path:'user.business.bank_accounts', arrayDefs: {getFirst: true, onNull: 'newBankAccount'}},
    {db:'banks', path: 'bank_accounts_bank' }]
  },*/
];

const requiredObjects = [
  'user.personal.address',
  'user.personal.tins',
  'user.personal.phone',
  'user.business.personal',
  'user.business.address',
  'user.business.tins',
  'user.business.phone',
  'user.business.personal.address',
  'user.business.personal.phone',
];

const arrayPaths = ['user.business.tins'];

const addressMinDefault = {
  id: null,
  country_id: null,
  city_id: null,
};

const verifiedEnumValue = "verificado";

export default {
  name: "FormsContainer",
  mixins: [formHelper, inputHelper, dateHelper, docsHelper],
  props: {
    investor_id: {
      type: [String,Number], required: true
    },
    user_id: {
      type: [String,Number], required: true
    },
    investor_status: {
      type: [String,Number], required: true
    },
    pfaeTabs: {
      type: Array, required: true
    },
    moralTabs: {
      type: Array, required: true
    },
    saveBtnId: {
      type: String, required: false, default: null
    },
  },
  data(){
    return {
      moralTypeValue: 0,
      moralForeignTypeValue: 3,
      moralFinancialTypeValue: 4,
      pfaeTypeValue: 1,
      pfForeignTypeValue: 2,
      flow_id_pfae: 11,
      flow_id_pm: 12,
      loading: false,
      isMounted: false,
      isRefreshing: false,
      errorMssg: '',
      successMssg: '',
      failed: false,
      flow: {},
      allSections: [],
      sectionGroups: [],
      documentsStates: [],
      addressHasCountry: false,

      tabs: [],

      investorType: null,
      isMoral: false,
      isPfae: false,
      activeTab: 0,

      missing: {
        enterprise: '',
        acredit: '',
        contact: '',
        residence: '',
        bank: ''
      },

      collections: {
        cities: [],
        states: [],
        countries: [],
        foreign_countries: [],
        banks: [],
        business_activities: [],
        personal_business_activities_categories: [],
        personal_business_activities: [],
        // sof_types_pf: [],
        // sof_types_pm: [],
        // average_deposits: [],
        personal_occupations: [],
        states_investor_personal_address: [],
        cities_investor_personal_address: [],
        neighborhoods_investor_personal_address: [],
        states_investor_business_address: [],
        cities_investor_business_address: [],
        neighborhoods_investor_business_address: [],
      },

      // objeto principal
      base: {},

      // auxiliares para evaluacion de condiciones
      mexicoCountryId: 700,

      // objetos para actualizaciones
      basePayload: {},
      validatedFields: [],
      totalFields: 0,
      totalValidFields: 0,
      totalInvalidFields: 0,

      validatedDocuments: [],
      allDocumentFields: [],

      skipMissingWarnings: false,

      currentInvestorId: null,
      currentInvestorUserId: null,
      currentInvestorStatus: null,

      isBackoffice: false,
      refreshingDocs: false,
    }
  },
  components: {
    FormField,
    PersonalProfileTinsControl,
    BusinessProfileTinsControl,
  },
  computed: {
    hasBackofficeAccess(){
      return this.$acl.check('isBackoffice');
    },
    personal(){
      return this.base.user.personal;
    },
    business(){
      return this.base.user.business;
    },
    businessOwner(){
      return this.base.user.business.personal;
    },
    /* show_only_if getters */
    should_block_rfc_validated(){
      let validated = false;
      if (this.isMoral) {
        if (this.business.rfc_verification_date) {
          validated = true;
        }
      }else{
        if (this.personal.rfc_verification_date) {
          validated = true;
        }
      }
      return validated;
    },
    // TODO-DELETE 10/01/2023 se usaba en la seccion 102
    // should_block_pf_address_country(){
    //   return this.base.user.person_type == 1 && this.addressHasCountry;
    // },
    should_fill_person_type_0_or_3(){
      return this.base.user.person_type == 0 || this.base.user.person_type == 3;
    },
    should_fill_person_type_0_or_4(){
      return this.base.user.person_type == 0 || this.base.user.person_type == 4;
    },
    should_fill_person_type_3_or_4(){
      return this.base.user.person_type == 3 || this.base.user.person_type == 4;
    },
    should_fill_person_type_0(){
      return this.base.user.person_type == 0;
    },
    should_fill_person_type_1(){
      return this.base.user.person_type == 1;
    },
    should_fill_person_type_2(){
      return this.base.user.person_type == 2;
    },
    should_fill_person_type_3(){
      return this.base.user.person_type == 3;
    },
    should_fill_person_type_1_tax_profile_1(){
      return this.base.user.person_type == 1
        && this.personal.tax_profile == 1;
    },
    should_fill_person_type_2_tax_profile_1(){
      return this.base.user.person_type == 2
        && this.personal.tax_profile == 1;
    },
    should_fill_personal_fiscal_address(){
      return !this.base.user.personal.fiscal_address.address_equals_fiscal_address;
    },
    should_fill_business_fiscal_address(){
      if(this.business.fiscal_address.address_equals_fiscal_address != null){
        return !this.business.fiscal_address.address_equals_fiscal_address;
      }
      else{ return false; }
    },
    should_fill_personal_mexican_address(){
      return this.personal.address.country_id == this.mexicoCountryId;
    },
    should_fill_personal_foreign_address(){
      return this.personal.address.country_id != this.mexicoCountryId;
    },
    should_fill_business_mexican_address(){
      return this.business.address.country_id == this.mexicoCountryId;
    },
    should_fill_business_foreign_address(){
      return this.business.address.country_id != this.mexicoCountryId;
    },
    should_fill_business_mexican_fiscal_address(){
      return this.should_fill_business_fiscal_address
        && this.business.fiscal_address.country_id == this.mexicoCountryId;
    },
    should_fill_business_foreign_fiscal_address(){
      return this.should_fill_business_fiscal_address
        && this.business.fiscal_address.country_id != this.mexicoCountryId;
    },
    should_fill_pm_rfc(){
      return this.business.nationality == this.mexicoCountryId;
    },
    should_fill_business_tins(){
      return this.business.nationality && this.business.nationality != this.mexicoCountryId;
    },
    should_fill_personal_rfc(){
      return this.personal.citizenship == this.mexicoCountryId;
    },
    should_fill_owner_rfc_curp_fiscal_rec(){
      return this.businessOwner.citizenship == this.mexicoCountryId;
    },
    should_fill_owner_tins(){
      return this.businessOwner.citizenship && this.businessOwner.citizenship != this.mexicoCountryId;
    },
    should_fill_personal_curp(){
      return this.personal.citizenship == this.mexicoCountryId;
    },
    should_fill_owner_curp(){
      return this.businessOwner.citizenship == this.mexicoCountryId;
    },
    power_proof_is_required(){
      return this.business.power_proof_required;
    },
    should_fill_pm_foreign_bank_info(){
      return this.business.nationality != this.mexicoCountryId && !this.businessBankAccount.pm_bank_is_in_mexico;
    },
    should_fill_pm_clabe(){
      return (this.business.nationality == this.mexicoCountryId || this.businessBankAccount.pm_bank_is_in_mexico == true);
    },
    should_fill_pm_swift_bic(){
      return this.should_fill_pm_foreign_bank_info;
    },
    should_fill_pm_account_number(){
      return this.should_fill_pm_foreign_bank_info && (this.businessBankAccount.iban == null || this.businessBankAccount.iban == "");
    },
    should_fill_pm_routing_number(){
      return this.should_fill_pm_foreign_bank_info && (this.businessBankAccount.iban == null || this.businessBankAccount.iban == "");
    },
    should_fill_pm_iban(){
      return this.should_fill_pm_foreign_bank_info;
    },
    should_fill_pm_bank_in_mexico(){
      return this.business.nationality != this.mexicoCountryId;
    },
    should_ask_for_pm_bank_confirmation(){
      return this.business.nationality != this.mexicoCountryId;
    },

    should_fill_pf_foreign_bank_info(){
      return this.personal.address.country_id != this.mexicoCountryId && this.base.user.personal.bank_accounts && this.base.user.personal.bank_accounts.length > 0
        && this.base.user.personal.bank_accounts[0].pf_bank_is_in_mexico != true;
    },
    should_fill_pf_clabe(){
      return (this.personal.citizenship == this.mexicoCountryId || this.personalBankAccount.pf_bank_is_in_mexico == true);
    },
    should_fill_pf_swift_bic(){
      return this.should_fill_pf_foreign_bank_info;
    },
    should_fill_pf_account_number(){
      return this.should_fill_pf_foreign_bank_info && (this.personalBankAccount.iban == null || this.personalBankAccount.iban == "");
    },
    should_fill_pf_routing_number(){
      return this.should_fill_pf_foreign_bank_info && (this.personalBankAccount.iban == null || this.personalBankAccount.iban == "");
    },
    should_fill_pf_iban(){
      return this.should_fill_pf_foreign_bank_info;
    },
    should_fill_pf_bank_in_mexico(){
      return this.personal.citizenship != this.mexicoCountryId;
    },
    should_ask_for_pf_bank_confirmation(){
      return this.personal.address.country_id != this.mexicoCountryId;
    },
    should_fill_personal_tins(){
      return this.personal.citizenship && this.personal.citizenship != this.mexicoCountryId;
    },
    should_fill_birth_state(){
      let show = true;
      if (this.personal.origin_country != this.mexicoCountryId) {
        show = false;
      }else{
        let c = this.personal.curp;
        if(c && c.length === 18){
          let c_birth_state = c.slice(11,13);
          if (c_birth_state === "NE") {
            show=false;
          }
        }
      }
      return show;
    },
    should_fill_personal_last_name_2(){
      let show = true;
      let c = this.personal.curp;
      if(c && c.length === 18){
        let last_name_2_first_letter = c.slice(2,3);
        let last_name_2_consonant = c.slice(14,15);
        if (last_name_2_first_letter === "X" && last_name_2_consonant === "X") {
          show=false;
        }
      }
      return show;
    },

    /* disabled_if getters */
    should_fill_personal_bdate_gender(){
      return this.personal.citizenship == this.mexicoCountryId;
    },
    should_fill_owner_bdate_gender(){
      return this.businessOwner.citizenship == this.mexicoCountryId;
    },
    personal_clabe_from_bank(){
      return this.clabeBankName;
    },
  },
  async beforeMount() {
    this.showLoading(true);
    this.isMounted = false;
    this.isBackoffice = process.env.VUE_APP_IS_BACKOFFICE == "true";
    this.setInvestor();
    await this.getCollections();
    await this.getInvestorData();
    this.setPersonTypeInfo();
    await this.setCustomIntegrations();
    await this.getFormInfo();
    this.setSectionGroups();
    this.setBlockedDocuments();
    this.isMounted = true;
    this.showLoading(false);
  },
  methods: {
    async reloadFormInfo(){
      this.isRefreshing = true;
      await this.getInvestorData();
      await this.setCustomIntegrations();
      this.reloadSectionGroups();
      // PARA USO DE UN SELECT CON ENUM EN DB
      // setea el valor de los average deposit para el select
      // this.set_average_deposit();
      this.set_personal_business_activities_category();
      this.isRefreshing = false;
    },
    setInvestor(){
      this.currentInvestorId = this.$props.investor_id;
      this.currentInvestorStatus = this.$props.investor_status;
      this.currentInvestorUserId = this.$props.user_id;
    },
    titleForTab(group){
      if(group.failed == 0){
        return group.title;
      }
      else {
        return `${group.title} ( ${group.failed} )`;
      }
    },
    resetCounters(){
      this.totalFields = 0;
      this.totalValidFields = 0;
      this.totalInvalidFields = 0;
    },
    async runSilentValidation(){
      this.resetCounters();
      let validator = new Validator();
      let container;
      await this.asyncForEach(this.sectionGroups, async (group) => {
        group.missing = [];
        group.failed = 0;
        await this.asyncForEach(group.sections, async (section) => {
          await this.asyncForEach(section.data.fields, async (f) => {
            container = this.getContainerForField(section, f);
            if(this.notDocumentNorPrivate(f)){
              if(this.isAutoFormField(f)){
                let res = await this.evaluateFieldCompletition(f, container, this.evaluateCondition, validator);
                //  console.log(section.data.id + ", " + f.fname + "=> " + res);
                if(res != null){
                  if(res == false){
                    section.failed += 1;
                    group.failed += 1;
                    group.missing.push({n: f.field_name, e: "Campo requerido"} );
                    this.totalInvalidFields += 1;
                  }
                }

                if(f.db_field === "rfc" && f.special_validation){
                  let res = await this[f.special_validation.method]();
                  let errors = res.length;
                  if(errors > 0){
                    section.failed += errors;
                    group.failed += errors;
                    this.totalInvalidFields += errors;
                    res.forEach(item => {
                      group.missing.push({n: item.n, e: item.e } );
                    })
                  }
                }
              }
              else if(f.special_definition == 1 && f.hard_validation){
                let res = await this[f.hard_validation.method]();
                if(!res){
                  section.failed += 1;
                  group.failed += 1;
                  group.missing.push({n: f.field_name, e:f.hard_validation.error } );
                  this.totalInvalidFields += 1;
                }
              }
              this.totalFields += 1;
            }
          });

          section.data.documentFields.forEach(df => {
            if(!df.loadedState && df.documentSet.is_required == 1){
              if(df.formField.show_conditioned){
                let inc = this.evaluateCondition(df.formField.condition);
                if(inc != true){
                  return;
                }
              }
              section.failed += 1;
              group.failed += 1;
              this.totalInvalidFields += 1;
              group.missing.push({n: df.documentSet.name, e: "Documento requerido"} );
            }
          })
        })
      });

    },
    async saveTabChanges(gslug){
      this.showLoading(true);
      this.skipMissingWarnings = false;
      let result = await this.runValidations(gslug);
      if(!result){
        if(!this.skipMissingWarnings){
          this.missingFieldsNotif();
        }
      }
      else {

        //guardar solo la informacion que se ha modificado
        this.basePayload = this.collectFormData(gslug);
        if(this.objectIsEmpty(this.basePayload)){
          //this.notFieldsToUpdate();
          this.saveSuccessNotif();
          this.showLoading(false);
          return;
        }

        this.basePayload.id = this.base.id;
        try {
          // ejecutar guardado
          this.injectAccountMetadataToPayload(this.basePayload);
          // PARA USO DE UN SELECT CON ENUM EN DB
          // this.on_save_average_deposit();
          await axios.put(`/api/v2/investor/${this.currentInvestorId}/deepUpdate`, this.basePayload);
          // solicitar la informacion actualizada del modelo
          await this.reloadFormInfo();

          // actualizar la informacion general del usuario en caso de ser necesario
          if(gslug == "general" && !this.isBackoffice){
            await this.onUserInfoUpdated();
          }

          this.saveSuccessNotif();
          this.$emit('on-form-saved', 1)
        }
        catch (error) {
          this.warn(error);
          this.failedOperationNotif(null, null);
        }
      }
      this.showLoading(false);
    },
    async runValidations(gslug){
      // validar componentes tipo FormField
      let res1 = await this.validateFormFields(gslug);
      if(!res1){
        return false;
      }

      // validar inputs instanciados en la vista
      let res2 = await this.$validator.validateAll();
      if(!res2){
        return false;
      }

      // inspeccionar los campos agregados directo en el componente para ejecutar validaciones especiales
      let group = this.sectionGroups.find(g => g.slug == gslug);
      let sp_failed = false;

      await this.asyncForEach(group.sections, async (s) => {
        await this.asyncForEach(s.data.fields.filter(f => f.special_definition == 1), async (f) => {
          // considerar los campos habilitados (visibles), con validaciones especiales
          if(this.fields[f.fname] && f.special_validation != null && f.special_validation.method != null) {
            let res = await this[f.special_validation.method]();

            if(!res){
              this.errors.add({
                field: f.fname,
                msg: f.special_validation.error
              });
              this.missingFieldsNotif(f.field_name, f.special_validation.error, 10000);
              sp_failed = true;
            }
          }
        });
      });

      if(sp_failed){
        this.skipMissingWarnings = true;
        return false;
      }

      // retornar true si todas las validaciones fueron positivas
      return true;
    },
    collectFormData(gslug){
      let payload = {};
      let group = this.sectionGroups.find(g => g.slug == gslug);
      group.sections.forEach(section => {
        section.containers.forEach(cc => {
          let fields = this.validatedFields.filter(vf => vf.db == cc.db);
          if(fields.length < 1){
            return;
          }

          if(cc.path == "base"){
            fields.forEach(f => {
              payload[f.fname] = cc.container[f.fname];
            });
          }
          else {
            let obj = null;
            if(arrayPaths.includes(cc.path)){
              // se integran todos los objetos de la coleccion
              obj = this.nestedFieldValue(this.base, cc.path);
            }
            else {
              obj = {id: cc.container.id};
              fields.forEach(f => {
                obj[f.fname] = cc.container[f.fname];
              });
            }
            // ajuste para objetos tipo array
            this.setDeepObjectWithIds(payload, this.base, cc.path, obj);
          }
        });
      });
      return payload;
    },
    async validateFormFields(refGroup){
      let allValid = true;
      this.validatedFields = [];
      let grefs = this.$refs[refGroup];

      let f;
      for(let p in grefs){
        f = grefs[p];

        let r = await f.checkForValidDirty();
        if(r.valid == false){
          allValid = false;
        }
        else if(r.dirty == true) {
          let sp = f.specialValidation();
          if(sp != null && sp.method in this){
            let res = await this[sp.method]();
            let n = f.rawFieldName;
            if(f.f.db_field === "rfc" && res.length !== 0) {
                allValid = false;
                res.forEach(item => {
                  this.errorNotif(item.n, item.e, 10000);
                })
            }
            if(!res){
              this.skipMissingWarnings = true;
              allValid = false;
              f.setError(sp.error);
              this.errorNotif(n, sp.error, 10000);
              continue;
            }
          }
          this.validatedFields.push(r);
        }
      }
      return allValid;
    },

    /* on change receptor */
    onFieldChange(method){
      if(method != null && method in this){
        this[method]();
      }
    },

    /* evaluator */
    evaluateCondition(condition){
      return this.evaluateConditionBase(this, condition);
    },

    /* obtener ids para los casos correspondientes */
    docTargetEvaluator(cls, cls_2){
      if(cls == 'user' && cls_2 == 'investor'){
        return this.currentInvestorUserId;
      }
      this.warn(`DOC TARGET NOT DEFINED FOR classifications: ${cls},${cls_2}`);
      return null;
    },

    setPersonTypeInfo(){
      this.investorType = this.base.user.person_type;
      this.isPfae = this.investorType == this.pfaeTypeValue || this.investorType == this.pfForeignTypeValue;
      this.isMoral = this.investorType == this.moralTypeValue || this.investorType == this.moralForeignTypeValue || this.investorType == this.moralFinancialTypeValue;
      if(this.isMoral){
        this.tabs = pmTabsDefs.filter(f => this.$props.moralTabs.includes(f.slug));
      }
      else {
        this.tabs = pfaeTabsDefs.filter(f => this.$props.pfaeTabs.includes(f.slug));
      }
    },
    async setCustomIntegrations(){
      // direcciones personal profile
      let padd_sid = this.nestedFieldValue(this.base, 'user.personal.address.state_id');
      if(padd_sid != null && padd_sid != ""){ await this.reload_personal_cities() }

      // direcciones business profile
      let badd_sid = this.nestedFieldValue(this.base, 'user.business.address.state_id');
      if(badd_sid != null && badd_sid != ""){ await this.reload_business_cities() }

      this.addressHasCountry = false;
      if(this.isPfae){
        this.addressHasCountry = (this.base.user.personal.address_id !== null && this.base.user.personal.address.country_id !== null);
      }

      if(this.isMoral){
        this.addressHasCountry = (this.base.user.business.address_id !== null && this.base.user.business.address.country_id !== null);
      }
    },
    setSectionGroups(){
      this.tabs.forEach(t => {
        let group = { title: t.title, icon: t.icon, slug: t.slug, missing: [] }; //, vModel: this.getModelForGroup(t.slug) };
        let sections = [];

        t.sections.forEach(ss => {
          let s = this.allSections.find(f => f.id == ss);
          if(s != null){
            let sb = { data: s, containers: this.getContainersForSection(s.id), failed: 0 };
            sections.push(sb);
          }
          else {
            this.warn("Section " + ss + " not found");
          }
        })
        group.sections = sections;
        group.failed = 0;
        this.sectionGroups.push(group);
      });
    },
    reloadSectionGroups(){
      this.sectionGroups.forEach(group => {
        group.sections.forEach(s => {
          s.containers = this.getContainersForSection(s.data.id);
        })
      });
    },
    /*getModelForGroup(slug){
      return this[slug];
    },*/
    getContainerForField(section, f){
      let c = section.containers.find(sc => sc.db == f.db_table);
      if(!c){
        this.warn(`Container not found for db [${f.fname}]: ${f.db_table}`);
        return null;
      }
      else {
        return c.container;
      }
    },
    getContainersForSection(section_id){
      let c = sectionsToContainers.find(f => f.id == section_id);
      if(!c){
        this.warn("Missing containers definition for section " + section_id);
        return null;
      }
      else {
        let sectionContainers = [];
        c.containers.forEach(cc => {
          let con = this.setContainerFromDef(cc);
          sectionContainers.push({db: cc.db, path: cc.path, container: con});
        })
        return sectionContainers;
      }
    },
    setContainerFromDef(c){
      let container = this.nestedFieldValue(this.base, c.path);
      if(Array.isArray(container)){
        let ac = null;
        if(c.arrayDefs){
          if(c.arrayDefs.getFirst == true){
            ac = container[0];
          }
          else if(c.arrayDefs.eval){
            // llamado en funciones de secciones
            ac = this[c.arrayDefs.eval](container);
          }
        }
        if(!ac){
          if(c.arrayDefs.onNull){ ac = this[c.arrayDefs.onNull](); }
          else { ac = {} }
          container.push(ac);
        }
        return ac;
      }
      else {
        if(c.path == 'bank_accounts_bank'){
          if(this.isMoral){
            let accs = this.nestedFieldValue(this.base, 'user.business.bank_accounts');
            let nbankAcc = accs[0];
            return nbankAcc.bank;
          }
          else {
            let accs = this.nestedFieldValue(this.base, 'user.personal.bank_accounts');
            let nbankAcc = accs[0];
            return nbankAcc.bank;
          }
        }
        if(container == null && c.arrayDefs) {
          let ac = null;
          if(c.arrayDefs.eval){
            // llamado en funciones de secciones
            ac = this[c.arrayDefs.eval]();
            return ac;
          }
          if(!ac){
            if(c.arrayDefs.onNull){ ac = this[c.arrayDefs.onNull](); }
            else { ac = {} }
            container = ac;
          }
        }
        return container;
      }
    },
    async getInvestorData(){
      try{
        // ?filter[status]=3 // ejemplo de filtro
        let params = "with[]=" + requiredObjects.join("&with[]=");
        let response = await axios.get(`/api/v2/investor/${this.currentInvestorId}?${params}`);
        this.base = response.data;
      }
      catch(e){
        this.warn(e);
        this.failed = true;
      }
    },
    async getFormInfo(){
      try{
        let id = this.isMoral == true ? this.flow_id_pm : this.flow_id_pfae;
        let response = await axios.get("/api/v1/forms/getFlowSectionFields/" + id);
        let url = this.ApiDomain + `/storagev3/documents/flow/${id}`;
        let response2 = await axios.get(url);
        this.documentsStates = response2.data;

        this.flow = response.data.flow;
        this.allSections = response.data.sections;
        this.allDocumentFields = [];

        await this.asyncForEach(this.allSections, async (s) => {
          if(this.tabs.filter(tt => tt.sections.includes(s.id)).length < 1){
            return;
          }

          s.documentFields = [];
          await this.asyncForEach(s.fields, async (f) => {
            this.formFieldSetter(f, this);

            // check for documents
            if(f.doc_id != null){
              let ff = {formField: f, documentSet: null, target_id: null };
              let ss = this.documentsStates.find(sid => sid.id == s.id);
              if(ss && ss.documents){
                let dset = ss.documents.find(d => d.id == f.doc_id);
                if(dset){
                  ff.target_id = this.docTargetEvaluator(dset.clasification, dset.clasification_2);
                  ff.documentSet = dset;
                }
              }
              s.documentFields.push(ff);
              this.allDocumentFields.push(ff);
            }
          });

          //   let states = await this.getAllDocumentsStates(allDocumentFields, this.evaluateCondition);

          // solicitar los estatus de cada documento
          /*
          let file = await this.getDocumentState(ff.target_id, f.doc_id, dset.clasification,
          this.evaluateCondition, f.extras);
          ff.loadedState = file;

          // agregar a la lista de documentos verificados
          if(file && file.is_verified == verifiedEnumValue){
            this.validatedDocuments.push(f.doc_id);
          }*/


        });

        let res = await this.getAllDocumentsStates(this.allDocumentFields, this.evaluateCondition);
        if(res){
          this.allDocumentFields.forEach(f => {
            if(f.loadedState && f.loadedState.is_verified == verifiedEnumValue){
              this.validatedDocuments.push(f.documentSet.id);
            }
          })
        }
      }
      catch(e){
        this.warn(e);
        this.failed = true;
      }
    },
    onDocumentUploadSuccess(data){
      let d = this.allDocumentFields.find(f => f.documentSet.id == data.document_id);
      d.loadedState = data;
    },
    onDocumentRejected(data){
      let d = this.allDocumentFields.find(f => f.documentSet.id == data.document_id);
      d.loadedState = data;
    },
    onDocumentVerified(data){
      let d = this.allDocumentFields.find(f => f.documentSet.id == data.document_id);
      d.loadedState = data;
      this.validatedDocuments.push(d.documentSet.id);
      this.allSections.forEach(section => {
        section.fields.filter(f => f.block_by_document_id == data.document_id).forEach(fd => fd.blockedByDocument = true);
      })
    },
    onDocumentVerificationRevoked(data){
      let d = this.allDocumentFields.find(f => f.documentSet.id == data.document_id);
      d.loadedState = data;
      this.validatedDocuments = this.validatedDocuments.filter(f => f != d.documentSet.id);
      this.allSections.forEach(section => {
        section.fields.filter(f => f.block_by_document_id == data.document_id).forEach(fd => fd.blockedByDocument = false);
      })
    },
    async getCollections(){
      try {
        let collectionsObjects = ['countriesList', 'foreignCountriesList', 'statesList',
          'banksForMexico', 'businessActivities', 'personalOccupationsList', 'personalBusinessActivityCategoryList'];
        let params = "with[]=" + collectionsObjects.join("&with[]=");
        let res = await axios.get(`/api/v1/forms/getFormCollections?${params}`);
        let colls = res.data;
        this.collections.countries = colls.countriesList;
        this.collections.foreign_countries = colls.countriesList;
        this.collections.states = colls.statesList;
        this.collections.banks = colls.banksForMexico;
        this.collections.business_activities = colls.businessActivities;
        this.collections.personal_business_activities_categories = colls.personalBusinessActivityCategoryList;
        // this.collections.sof_types_pf = colls.sofTypes.filter(f => f.kind === 'PF');
        // this.collections.sof_types_pm = colls.sofTypes.filter(f => f.kind === 'PM');
        // this.collections.average_deposits = colls.averageDeposits;
        this.collections.personal_occupations = colls.personalOccupationsList;
      } catch (e) {
        this.warn(e);
        this.failed = true;
      }
    },

    setBlockedDocuments(){
      this.validatedDocuments.forEach(doc => {
        this.allSections.forEach(section => {
          section.fields.filter(f => f.block_by_document_id == doc).forEach(fd => fd.blockedByDocument = true);
        })
      })
    },

    /* on_change methods */
    async reload_personal_cities(){
      try {
        let sid = this.personal.address.state_id;
        let res = await axios.get(`/api/register/cities/${sid}`);
        this.collections.cities = res.data;
        if(this.isMounted && !this.isRefreshing) this.personal.address.city_id = null;
      } catch (e) {
        this.warn(e);
      }
    },
    async reload_personal_cities_fiscal(){
      try {
        let sid = this.personal.fiscal_address.state_id;
        let res = await axios.get(`/api/register/cities/${sid}`);
        this.collections.fiscal_cities = res.data;
        if(this.isMounted && !this.isRefreshing) this.personal.fiscal_address.city_id = null;
      } catch (e) {
        this.warn(e);
      }
    },
    async reload_business_cities(){
      try {
        let sid = this.business.address.state_id;
        let res = await axios.get(`/api/register/cities/${sid}`);
        this.collections.cities = res.data;
        if(this.isMounted && !this.isRefreshing) this.business.address.city_id = null;
      } catch (e) {
        this.warn(e);
      }
    },
    async reload_business_cities_fiscal(){
      try {
        let sid = this.business.fiscal_address.state_id;
        let res = await axios.get(`/api/register/cities/${sid}`);
        this.collections.fiscal_cities = res.data;
        if(this.isMounted && !this.isRefreshing) this.business.fiscal_address.city_id = null;
      } catch (e) {
        this.warn(e);
      }
    },
    personal_curp_conditions_changed(){
      let c = this.personal.curp;
      if(!c){ return; }
      if(c.length >= 10){
        let yy = c.slice(4,6);
        let mm = c.slice(6,8);
        let dd = c.slice(8,10);

        let date = this.getDateFromInputs(yy, mm, dd);
        if(date === false){
          this.personal.birth_date = null;
        }
        else{
          this.personal.birth_date = date;
        }
      }
      else{
        this.personal.birth_date = null;
      }

      if(c.length >= 11){
        let g = c.slice(10, 11);
        if(g == "H"){
          this.personal.gender = 1;
        }
        else if(g == "M"){
          this.personal.gender = 2;
        }
        else{
          this.personal.gender = null;
        }
      }
      else{
        this.personal.gender = null;
      }

      if(c.length >= 13){
        let code = c.slice(11, 13);
        let state = this.collections.states.find(f => f.renapo_code == code)
        if(state != null){
          this.personal.birth_state = state.id;
        }
      }
      else{
        this.personal.birth_state = null;
      }
    },
    owner_curp_conditions_changed(){
      let c = this.businessOwner.curp;
      if(!c){ return; }
      if(c.length >= 10){
        let yy = c.slice(4,6);
        let mm = c.slice(6,8);
        let dd = c.slice(8,10);

        let date = this.getDateFromInputs(yy, mm, dd);
        if(date === false){
          this.businessOwner.birth_date = null;
        }
        else{
          this.businessOwner.birth_date = date;
        }
      }
      else{
        this.businessOwner.birth_date = null;
      }

      if(c.length >= 11){
        let g = c.slice(10, 11);
        if(g == "H"){
          this.businessOwner.gender = 1;
        }
        else if(g == "M"){
          this.businessOwner.gender = 2;
        }
        else{
          this.businessOwner.gender = null;
        }
      }
      else{
        this.businessOwner.gender = null;
      }

      if(c.length >= 13){
        let code = c.slice(11, 13);
        let state = this.collections.states.find(f => f.renapo_code == code)
        if(state != null){
          this.businessOwner.birth_state = state.id;
        }
      }
      else{
        this.personal.birth_state = null;
      }
    },

    /* funciones de secciones */
    filterGeneralContact(collection){
      return collection.find(f => f.type == 1);
    },
    filterCommercialContact(collection){
      return collection.find(f => f.type == 2);
    },
    filterOperationsContact(collection){
      return collection.find(f => f.type == 3);
    },
    newGeneralContact(){
      return {type: 1};
    },
    newCommercialContact(){
      return {type: 2};
    },
    newOperationsContact(){
      return {type: 3};
    },
    newBankAccount(){
      return {id: null, bank: {}};
    },
    newPhone(){
      return {country_calling_code: null, phone: null};
    },

    /* validaciones especiales */
    validate_user_birthdate(){
      let years = this.calculateAgeFromDate(this.personal.birth_date);
      return years >= 18;
    },

    validate_owner_birthdate(){
      let years = this.calculateAgeFromDate(this.businessOwner.birth_date);
      return years >= 18;
    },
    validate_personal_profile_tins(){
      if(this.$refs.personal_profile_tins_component[0] != null){
        return this.$refs.personal_profile_tins_component[0].validate();
      }
      else {
        return true;
      }
    },
    validate_business_profile_tins(){
      if(this.$refs.business_profile_tins_component != null){
        return this.$refs.business_profile_tins_component.validate();
      }
      else {
        return true;
      }
    },
    async validate_personal_unique_curp(){
      return await this.validate_unique_curp(this.personal.curp, this.personal.id)
    },
    async validate_owner_unique_curp(){
      return await this.validate_unique_curp(this.businessOwner.curp, this.businessOwner.id)
    },
    async validate_personal_clabe_is_valid(){
      return this.validate_clabe(this.personalBankAccount.clabe, this.collections.banks);
    },
    async validate_business_clabe_is_valid(){
      return this.validate_clabe(this.businessBankAccount.clabe, this.collections.banks);
    },
    async validate_owner_clabe_is_valid(){
      return this.validate_clabe(this.businessOwner.bank_accounts[0].clabe, this.collections.banks);
    },
    async validate_personal_unique_rfc(){
      return await this.validate_unique_personal_rfc(this.personal.rfc, this.personal.id)
    },
    async validate_pm_unique_rfc(){
      return await this.validate_unique_business_rfc(this.business.rfc, this.business.id)
    },
    async validate_owner_unique_rfc(){
      return await this.validate_unique_personal_rfc(this.businessOwner.rfc, this.businessOwner.id)
    },
    async onUnlockBankAccountRequest(){
      let mssg = "Si confirmas que deseas actualizar los datos de tu cuenta bancaria, la nueva información tendrá que ser validada, " +
        "por lo que no podrás realizar retiros hasta que la validación concluya." +
        "\n\nRecuerda que deberás actualizar también el encabezado de cuenta que coincida con tus nuevos datos bancarios.";
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: "¿Deseas actualizar tus datos bancarios?",
        text: mssg,
        acceptText: "Confirmar",
        cancelText: "Cancelar",
        accept: this.doUnlockBankAccount,
      });
    },
    async doUnlockBankAccount(){
      this.showLoading(true);
      try {
        const res = await axios.post(`api/v2/investor/${this.InvestorId}/unlockBankAccount`, {});
        const document_data = res.data.document_data;
        await this.reloadFormInfo();

        let d = this.allDocumentFields.find(f => f.documentSet.id == document_data.document_id);
        d.loadedState = document_data;
        this.validatedDocuments = this.validatedDocuments.filter(f => f != d.documentSet.id);
        this.allSections.forEach(section => {
          section.fields.filter(f => f.block_by_document_id == document_data.document_id).forEach(fd => fd.blockedByDocument = false);
        })

        const g = this.sectionGroups.find(f => f.slug === "bank");
        const section_id = this.isMoral ? 107 : 103;
        let section = g.sections.find(f => f.data.id === section_id);
        const dd = section.data.documentFields.find(f => f.documentSet.id == document_data.document_id);
        dd.loadedState = document_data;
        this.refreshingDocs = true;
        this.$forceUpdate();
        await this.timeout(200);
        this.refreshingDocs = false;
        this.$forceUpdate();
        this.saveSuccessNotif();
      }
      catch (e) {
        console.log(e);
        this.failedOperationNotif();
      }
      this.showLoading(false);
    },
    timeout(ms){
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    verify_zip_code_investor_personal_address() {
      this.verifyZipCode(this.personal.address, {
        zipCodeVariable: 'zip_code_investor_personal_address', 
        statesArray: 'states_investor_personal_address', 
        citiesArray: 'cities_investor_personal_address', 
        neighborhoodsArray: 'neighborhoods_investor_personal_address'
      });
    },
    set_zip_code_values_investor_personal_address() {
      this.setZipCodeAddressValues(this.personal.address_id, this.personal.address, {
        zipCodeVariable: 'zip_code_investor_personal_address',
        statesArray: 'states_investor_personal_address',
        citiesArray: 'cities_investor_personal_address',
        neighborhoodsArray: 'neighborhoods_investor_personal_address'
      });
    },
    verify_zip_code_investor_business_address() {
      this.verifyZipCode(this.business.address, {
        zipCodeVariable: 'zip_code_investor_business_address', 
        statesArray: 'states_investor_business_address', 
        citiesArray: 'cities_investor_business_address', 
        neighborhoodsArray: 'neighborhoods_investor_business_address'
      });
    },
    set_zip_code_values_investor_business_address() {
      this.setZipCodeAddressValues(this.business.address_id, this.business.address, {
        zipCodeVariable: 'zip_code_investor_business_address',
        statesArray: 'states_investor_business_address',
        citiesArray: 'cities_investor_business_address',
        neighborhoodsArray: 'neighborhoods_investor_business_address'
      });
    },
    set_personal_business_activities_category() {
      if (this.personal.personal_business_activity_id == null || this.personal.personal_business_activity_id == "") {
        return;
      }
      let match = null;
      let categorySelected = null;
      for (const category of this.collections.personal_business_activities_categories) {
        match = category.activities.find(x => x.id === this.personal.personal_business_activity_id);
        if (match != undefined) {
          categorySelected = category;
          break;
        }
      }
      this.collections.personal_business_activities = categorySelected.activities;
      this.personal.personal_business_activity_category_id = categorySelected.id;
    },
    set_personal_business_activities() {
      if (this.personal.personal_business_activity_category_id == null || this.personal.personal_business_activity_category_id == "") {
        return;
      }
      let categorySelected = this.collections.personal_business_activities_categories.find(x => x.id === this.personal.personal_business_activity_category_id);
      this.collections.personal_business_activities = categorySelected.activities;
      this.personal.personal_business_activity_id = null;
    },
    // FUNCIONES PARA USO DE UN SELECT CON ENUM EN DB HAY QUE SETEAR EN LOS JSON DE LA SECCION
    // "collection": {
    //    "object_key": "index",
    //  "extras": {
		//    "on_mounted": "set_sof_type",
    // set_average_deposit() {
    //   if (this.base.kyc_profile.initial_trx_profile_average_deposit == null || this.base.kyc_profile.initial_trx_profile_average_deposit == "") {
    //     return;
    //   }
    //   let enumCollection = this.collections.average_deposits;
    //   let newValue = enumCollection.find(x => x.id === this.base.kyc_profile.initial_trx_profile_average_deposit);
    //   this.base.kyc_profile.initial_trx_profile_average_deposit = newValue.index;
    // },
    // on_save_average_deposit() {
    //   let enumCollection = this.collections.average_deposits;
    //   let newValue = enumCollection.find(x => x.index === this.base.kyc_profile.initial_trx_profile_average_deposit);
    //   this.basePayload.kyc_profile.initial_trx_profile_average_deposit = newValue.id;
    // },
  }
}
</script>